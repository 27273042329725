import { Stack, ActionButton } from "@fluentui/react";
import { Copy24Filled, Copy24Regular, ErrorCircle24Regular } from "@fluentui/react-icons";

import styles from "./Answer.module.css";
import { useState } from "react";

interface Props {
    error: string;
    onRetry: () => void;
}

export const AnswerError = ({ error, onRetry }: Props) => {
    const [copySelect, setCopySelect] = useState<boolean>(false);

    const [isErrorHovered, setIsErrorHovered] = useState(false);

    const handleMouseOverError = () => setIsErrorHovered(true);
    const handleMouseOutError = () => setIsErrorHovered(false);

    //add a if condition to check if the error variable constians a value  "The Assistant is specifically tailored for UK employees" and render just the error message without any retry button
    if (error.includes("The Assistant is specifically tailored for UK employees")) {
        //console.log(error);
        // "The Assistant is designed and provided exclusively for use by employees based in the United Kingdom."
        let cleanedError = error.substring(error.indexOf(":") + 2);

        console.log(cleanedError);
        return (
            <div onMouseOver={handleMouseOverError} onMouseOut={handleMouseOutError}>
                <Stack
                    horizontal
                    style={{
                        marginRight: "10px",
                        justifyContent: "end",
                        marginTop: "20px",
                        height: "32px",
                        cursor: "pointer",
                        fontFamily: "FordMotionRegular"
                    }}
                >
                    {isErrorHovered && (
                        <Stack
                            horizontal
                            style={{
                                backgroundColor: copySelect ? "#eaeaea" : "#f9f9f9",
                                borderRadius: "5px",
                                width: copySelect ? "83px" : "70px",
                                boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19)",
                                paddingLeft: "3px",
                                paddingRight: "3px"
                            }}
                            className={styles.copyErrorSelectHover}
                        >
                            {!copySelect && (
                                <Stack horizontal style={{ marginTop: "5px" }}>
                                    <div style={{ color: "#2047ef" }}>
                                        <Copy24Regular />
                                    </div>
                                    <div
                                        onClick={() => {
                                            setCopySelect(true);
                                            navigator.clipboard.writeText(error);
                                            setTimeout(() => {
                                                setCopySelect(false);
                                            }, 1000);
                                        }}
                                        title="Copy respose here"
                                    >
                                        Copy
                                    </div>
                                </Stack>
                            )}
                            {copySelect && (
                                <Stack horizontal style={{ marginTop: "5px" }}>
                                    <div style={{ color: "#2047ef" }}>
                                        <Copy24Filled />
                                    </div>
                                    <div title="Copied">Copied</div>
                                </Stack>
                            )}
                        </Stack>
                    )}
                </Stack>
                <Stack className={styles.answerContainer} verticalAlign="space-between">
                    {/* <ErrorCircle24Regular aria-hidden="true" aria-label="Error icon" primaryFill="red" /> */}
                    <Stack.Item grow>
                        <p className={styles.answerText}>{cleanedError}</p>
                    </Stack.Item>
                </Stack>
            </div>
        );
    } else {
        return (
            <div onMouseOver={handleMouseOverError} onMouseOut={handleMouseOutError}>
                <Stack
                    horizontal
                    style={{
                        marginRight: "10px",
                        justifyContent: "end",
                        marginTop: "20px",
                        height: "32px",
                        cursor: "pointer",
                        fontFamily: "FordMotionRegular"
                    }}
                >
                    {isErrorHovered && (
                        <Stack
                            horizontal
                            style={{
                                backgroundColor: copySelect ? "#eaeaea" : "#f9f9f9",
                                borderRadius: "5px",
                                width: copySelect ? "83px" : "70px",
                                boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19)",
                                paddingLeft: "3px",
                                paddingRight: "3px"
                            }}
                            className={styles.copyErrorSelectHover}
                        >
                            {!copySelect && (
                                <Stack horizontal style={{ marginTop: "5px" }}>
                                    <div style={{ color: "#2047ef" }}>
                                        <Copy24Regular />
                                    </div>
                                    <div
                                        onClick={() => {
                                            setCopySelect(true);
                                            navigator.clipboard.writeText(error);
                                            setTimeout(() => {
                                                setCopySelect(false);
                                            }, 1000);
                                        }}
                                        title="Copy respose here"
                                    >
                                        Copy
                                    </div>
                                </Stack>
                            )}
                            {copySelect && (
                                <Stack horizontal style={{ marginTop: "5px" }}>
                                    <div style={{ color: "#2047ef" }}>
                                        <Copy24Filled />
                                    </div>
                                    <div title="Copied">Copied</div>
                                </Stack>
                            )}
                        </Stack>
                    )}
                </Stack>
                <Stack className={styles.answerContainer} verticalAlign="space-between">
                    <ErrorCircle24Regular aria-hidden="true" aria-label="Error icon" primaryFill="red" />

                    <Stack.Item grow>
                        <p className={styles.answerText}>{error}</p>
                    </Stack.Item>

                    <ActionButton className={styles.retryButton} onClick={onRetry} title="Retry sending query" text="Retry" />
                </Stack>
            </div>
        );
    }
};
