import React, { useState } from "react";
import { Icon, IconButton } from "@fluentui/react";
import "./LikeDislike.css";

interface LikeBtnProps {
    isSelected: boolean;
    direction: "Like" | "Dislike";
    messageId: number;
    onSendFeedback: (messageId: number, action: string) => void;
    onSendFeedbackMessage: (messageId: number, feedback: string, feedbackCategory: string | null) => void;
    setAnswerFeedbackForm: (status: boolean) => void;
}
export const LikeBtn = ({ isSelected, direction, messageId, onSendFeedback, onSendFeedbackMessage, setAnswerFeedbackForm }: LikeBtnProps) => {
    const [feedbackForm, setFeedbackForm] = useState(false);
    const [message, setMessage] = useState("");
    const iconName = direction + (isSelected ? "Solid" : "");
    const action = direction == "Like" ? "up" : "down";
    const title = direction == "Like" ? "I like this response." : "I did not like this response.";

    const onFeedbackClicked = async () => {
        onSendFeedback(messageId, action);
    };
    const onRemoveFeedback = async () => {
        onSendFeedback(messageId, "0");
    };
    const toggleFeedbackForm = () => {
        setFeedbackForm(!feedbackForm);
    };
    const onSubmitFeedBackForm = async () => {
        setFeedbackForm(!feedbackForm);
        onSendFeedbackMessage(messageId, message, "na");
        //alert("Thank you for your valuable Feedback!");
        //Placeholder for adding code whcih required to connect the backend to save the Feedback message.
    };

    if (action === "up") {
        return (
            <>
                <IconButton
                    style={{ color: "#2047ef" }}
                    iconProps={{ iconName }}
                    title={title}
                    ariaLabel={title}
                    onClick={() => {
                        if (iconName === "LikeSolid") {
                            onRemoveFeedback();
                        } else {
                            onFeedbackClicked();
                            setAnswerFeedbackForm(false);
                        }
                    }}
                />
            </>
        );
    } else if (action === "down") {
        return (
            <>
                <IconButton
                    style={{ color: "#2047ef" }}
                    iconProps={{ iconName }}
                    title={title}
                    ariaLabel={title}
                    onClick={() => {
                        if (iconName === "DislikeSolid") {
                            onRemoveFeedback();
                            setAnswerFeedbackForm(false);
                        } else {
                            onFeedbackClicked();
                            toggleFeedbackForm();
                            setAnswerFeedbackForm(true);
                        }
                    }}
                />
            </>
        );
    } else {
        return <div></div>;
    }
};