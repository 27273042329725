import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";

import styles from "./Answer.module.css";

interface Props {
    greetings?: string;
}

export const AnswerLoading = ({ greetings="" }: Props) => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack horizontal>
            {greetings !== "" ? (
                    <Stack.Item grow>
                        <p className={styles.answerText}>
                            <span style={{ color: "#13a210", fontSize: "25px" }}>✔&ensp;</span>Please wait while loading
                            <span className={styles.loadingdots} />
                        </p>
                    </Stack.Item>
                ) : (
                    <Stack.Item grow>
                        <p className={styles.answerText}>
                            <span style={{ color: "#13a210", fontSize: "25px" }}>✔&ensp;</span>Generating answer for you
                            <span className={styles.loadingdots} />
                        </p>
                    </Stack.Item>
                )}
            </Stack>
        </animated.div>
    );
};
