import styles from "./UserChatMessage.module.css";

import "../../assets/fonts/FordMotion/woff/FordMotion-Regular.woff";
import { Stack } from "@fluentui/react";
import { useState } from "react";
import { Copy24Filled, Copy24Regular } from "@fluentui/react-icons";

interface Props {
    message: string;
    greetings: string;
}

export const UserChatMessage = ({ message, greetings }: Props) => {
    const [copySelect, setCopySelect] = useState<boolean>(false);

    const [isQueryHovered, setIsQueryHovered] = useState(false);

    const handleMouseOverQuery = () => setIsQueryHovered(true);
    const handleMouseOutQuery = () => setIsQueryHovered(false);

    return (
        <div>
            <div className={styles.container}>
                {message !== greetings ? (
                    <>
                        <div onMouseOver={handleMouseOverQuery} onMouseOut={handleMouseOutQuery}>
                            <Stack
                                horizontal
                                style={{
                                    marginRight: "10px",
                                    justifyContent: "end",
                                    marginTop: "20px",
                                    height: "32px",
                                    cursor: "pointer",
                                    fontFamily: "FordMotionRegular"
                                }}
                            >
                                {isQueryHovered && (
                                    <Stack
                                        horizontal
                                        style={{
                                            backgroundColor: copySelect ? "#eaeaea" : "#f9f9f9",
                                            borderRadius: "5px",
                                            width: copySelect ? "83px" : "70px",
                                            boxShadow: "0 1px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.19)",
                                            paddingLeft: "3px",
                                            paddingRight: "3px"
                                        }}
                                        className={styles.copySelectHover}
                                    >
                                        {!copySelect && (
                                            <Stack horizontal style={{ marginTop: "5px" }}>
                                                <div style={{ color: "#2047ef" }}>
                                                    <Copy24Regular />
                                                </div>
                                                <div
                                                    onClick={() => {
                                                        setCopySelect(true);
                                                        navigator.clipboard.writeText(message);
                                                        setTimeout(() => {
                                                            setCopySelect(false);
                                                        }, 1000);
                                                    }}
                                                    title="Copy respose here"
                                                >
                                                    Copy
                                                </div>
                                            </Stack>
                                        )}
                                        {copySelect && (
                                            <Stack horizontal style={{ marginTop: "5px" }}>
                                                <div style={{ color: "#2047ef" }}>
                                                    <Copy24Filled />
                                                </div>
                                                <div title="Copied">Copied</div>
                                            </Stack>
                                        )}
                                    </Stack>
                                )}
                            </Stack>

                            <Stack className={styles.message}>{message}</Stack>
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
};