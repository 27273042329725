import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route, BrowserRouter } from "react-router-dom";
import { initializeIcons, ActionButton, Stack } from "@fluentui/react";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import fordLogo from "../src/assets/fordIcon.png";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
export { PopupModal };
import PopupModal from "./components/Popup";

import logo from "./assets/Life@Ford.png";

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import OneShot from "./pages/oneshot/OneShot";
import Chat from "./pages/chat/Chat";
import { Person } from "@microsoft/mgt-react";

import "./assets/fonts/FordMotion/woff/FordMotion-Regular.woff";

import "./assets/fonts/FordAntenna/Antenna-Regular.woff";

import lockIcon from "./assets/lock.svg";

import rightArrow from "./assets/path.svg";

import rightArrowBlue from "./assets/pathBlue.svg";

import { getWebroleToken } from "./api";

const tenantId = "c990bb7a-51f4-439b-bd36-9c07fb1041c0";
const clientId = "ae5fc2ab-d8a9-4544-b282-23bf5d57bfed";
//ae5fc2ab-d8a9-4544-b282-23bf5d57bfed , lower : 36ab7d87-e480-43e7-a4fb-fb59a6c9589b , INT 4565b528-9822-48d5-949f-16e57e2403ee
//const redirectUri = "http://localhost:5173";
const redirectUri = "https://www.llm.hr.ford.com/";
//https://hrrevchatbot.azurewebsites.net/ , https://www.llm.hr.ford.com/ , http://localhost:5173

const msalInstance = new Msal2Provider({
    clientId: clientId,
    loginType: 1,
    redirectUri: redirectUri,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    scopes: ["offline_access", "openid", "profile", "User.Read"]
});

Providers.globalProvider = msalInstance;

const handleLogout = () => {
    sessionStorage.setItem("agreementFlag", "");
    sessionStorage.setItem("signout", "1");
    msalInstance.logout();
};

const gp = Providers.globalProvider;
function autoLogin() {
    if (!gp.login) return false;
    try {
        if (!gp.getActiveAccount) return false;
        const acc = gp.getActiveAccount();
        if (!acc) throw Error("noAccount");
    } catch (e) {
        gp.login();
    }
}
autoLogin();

function useIsSignedIn(): [boolean] {
    const [isSignedIn, setIsSignedIn] = useState(false);

    useEffect(() => {
        const updateState = () => {
            const provider = Providers.globalProvider;
            setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
        };

        Providers.onProviderUpdated(updateState);
        updateState();
        return () => {
            Providers.removeProviderUpdatedListener(updateState);
        };
    }, []);

    return [isSignedIn];
}

initializeIcons();

export default function App() {
    const [isSignedIn] = useIsSignedIn();
    const [isOpen, setIsOpen] = useState(true);
    const headerStyleNotSignedIn: React.CSSProperties = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center"
    };
    const [headColor, setHeadColor] = useState(false);
    const changeHeadColor = () => {
        if (window.scrollY >= 50) {
            setHeadColor(true);
        } else {
            setHeadColor(false);
        }
    };
    //Get webroles if signed in
    useEffect(() => {
        if (isSignedIn) {
            //console.log("executing hook for getWebroles");
            var token = getWebroleToken()
                .then(t => console.log("webrole token cached"))
                .catch(e => console.error("failed to get webrole token"));
            console.log(token);
        }
    }, [isSignedIn]);

    window.addEventListener("scroll", changeHeadColor);

    const [agreementFlag, setAgreementFlag] = useState(false);
    let ignore = false;
    useEffect(() => {
        if (!ignore) {
            if (sessionStorage.getItem("agreementFlag")) {
                setAgreementFlag(true);
            }
        }
        return () => {
            ignore = true;
        };
    }, []);

    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight
    });

    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight
        });
    };
    useEffect(() => {
        window.addEventListener("resize", detectSize);

        return () => {
            window.removeEventListener("resize", detectSize);
        };
    }, [windowDimension]);

    const [isDropdownVisibleName, setDropdownVisibleName] = useState(false);

    const handleMouseEnterName = () => {
        setDropdownVisibleName(true);
        if (window.scrollY < 50) setHeadColor(true);
    };

    const handleMouseLeaveName = () => {
        setDropdownVisibleName(false);
        if (window.scrollY < 50) setHeadColor(false);
    };

    return (
        <div className="App" style={{ backgroundImage: "linear-gradient(to right, #eff2f9, #d1e1f8)", height: "100%" }}>
            {!isSignedIn && (
                <header style={headerStyleNotSignedIn}>
                    <img src={fordLogo} alt="Ford Icon" />
                    <ActionButton
                        onClick={() => {
                            msalInstance.login();
                        }}
                        title="Click on here to Sign-In"
                        className="animatedButton"
                    >
                        <b>Log In</b>
                    </ActionButton>
                    <div style={{ fontFamily: "FordMotionRegular" }}>You should be redirected to login in a few seconds. If not, click on "Log In" button.</div>
                </header>
            )}
            {isSignedIn && !sessionStorage.getItem("agreementFlag") && (
                <Popup
                    open={isOpen}
                    onClose={() => setIsOpen(false)}
                    closeOnDocumentClick={false}
                    closeOnEscape={false}
                    contentStyle={{
                        width: "700px",
                        border: "1px",
                        padding: "20px",
                        borderRadius: "12px",
                        backgroundImage: "linear-gradient(to right, #eff2f9, #d1e1f8)",
                        textAlign: "center",
                        boxShadow: "1px 1px 10px 0px #2047ef",
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "FordMotionRegular",
                        marginLeft: windowDimension.winWidth <= 720 ? "10px" : "auto",
                        marginRight: windowDimension.winWidth <= 720 ? "10px" : "auto",
                        marginTop: windowDimension.winHeight <= 340 ? "10px" : "auto",
                        marginBottom: windowDimension.winHeight <= 340 ? "10px" : "auto"
                    }}
                >
                    <div>
                        <Stack horizontal style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "center" }}>
                            <div>
                                <img src={logo} alt="Life@Ford Logo" aria-label="Link to Life@Ford" width="195px" height="45px" />
                            </div>
                            <div style={{ fontWeight: "bold", marginTop: "11px", color: "#2047ef", fontFamily: "FordMotionRegular" }}>|| Pensions Chat</div>
                        </Stack>
                        <p style={{ textAlign: "left" }}>
                            USAGE AGREEMENT.
                            <br />
                            <br />
                            1. Avoid submitting Ford{" "}
                            <a href="https://azureford.sharepoint.com/sites/SIPP/SitePages/SecretClassification.aspx?web=1">secret information</a> in the chat
                            box.
                            <br />
                            <br />
                            2. Ford proprietary and{" "}
                            <a href="https://azureford.sharepoint.com/sites/SIPP/SitePages/ConfidentialClassification.aspx">confidential information</a> may be
                            submitted to Pensions Chat only.
                            <br /> Avoid submiting ford proprietary or confidential information to any public AI chat website.
                        </p>
                        <ActionButton
                            onClick={() => {
                                setIsOpen(false);
                                sessionStorage.setItem("agreementFlag", "1");
                                setAgreementFlag(true);
                            }}
                            title="Click on here to continue!"
                            className="animatedButton"
                        >
                            <b>I Agree And Continue</b>
                        </ActionButton>
                    </div>
                </Popup>
            )}
            {agreementFlag && isSignedIn && (
                <div>
                    {isDropdownVisibleName && <div className="overlay"></div>}
                    <header
                        style={{
                            position: "fixed",
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundImage: headColor ? "linear-gradient(white, white, white, white, white, white, transparent)" : "none",
                            width: "100%",
                            height: "70px",
                            top: "0px",
                            zIndex: "9999"
                        }}
                    >
                        <div style={{ position: "fixed", left: "0px" }}>
                            <Layout />
                        </div>
                        {windowDimension.winWidth <= 660 && (
                            <div onMouseEnter={handleMouseEnterName} onMouseLeave={handleMouseLeaveName} style={{ cursor: "pointer" }}>
                                <div style={{ position: "fixed", right: "10px", marginLeft: "10px", marginTop: "12px" }}>
                                    <Person personQuery="me" className="personStyle" />
                                </div>
                                <div style={{ position: "fixed", right: "5px", marginTop: "52px" }}>{isDropdownVisibleName && <LogoutButton />}</div>
                            </div>
                        )}
                        {windowDimension.winWidth > 660 && (
                            <div
                                style={{ position: "fixed", right: "10px", marginTop: "20px" }}
                                onMouseEnter={handleMouseEnterName}
                                onMouseLeave={handleMouseLeaveName}
                            >
                                <Stack
                                    horizontal
                                    style={{
                                        fontFamily: "FordAntennaRegular",
                                        fontSize: "12px",
                                        backgroundColor: "white",
                                        padding: "5px",
                                        border: "1px solid #00095b",
                                        width: "101%",
                                        color: "#00095b",
                                        cursor: "pointer"
                                    }}
                                >
                                    <img src={lockIcon} />
                                    <div style={{ marginTop: "2px", textTransform: "uppercase" }}>Welcome, {msalInstance.getActiveAccount().name}</div>
                                </Stack>
                                {isDropdownVisibleName && <LogoutButton />}
                            </div>
                        )}
                    </header>
                </div>
            )}
            <div>
                {agreementFlag && isSignedIn && (
                    <BrowserRouter>
                        <Routes>
                            <Route index element={<Chat />} />
                            <Route path="/qa" element={<OneShot />} />
                            <Route path="*" element={<NoPage />} />
                        </Routes>
                    </BrowserRouter>
                )}
            </div>
        </div>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

const LogoutButton = () => {
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };
    return (
        <div className="dropdown-menu">
            <ul className="listBox">
                <li className="list" onClick={handleLogout} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                    Log Out &nbsp; &nbsp; &nbsp;{" "}
                    <span style={{ float: "right", marginTop: "2px" }}>
                        {!isDropdownVisible && <img src={rightArrow} />}
                        {isDropdownVisible && <img src={rightArrowBlue} />}
                    </span>
                </li>
            </ul>
        </div>
    );
};
