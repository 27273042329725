import { Outlet, NavLink, Link } from "react-router-dom";
import { Stack, ActionButton } from "@fluentui/react";
import { Search20Filled, ArrowExitFilled } from "@fluentui/react-icons";
import { useState, useEffect } from "react";

import logo from "../../assets/Life@Ford.png";

import styles from "./Layout.module.css";

import "../../assets/fonts/FordMotion/woff/FordMotion-Regular.woff";

import "../../assets/fonts/FordAntenna/Antenna-Regular.woff";

const Layout = () => {
    const [windowDimension, detectHW] = useState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight
    });

    const detectSize = () => {
        detectHW({
            winWidth: window.innerWidth,
            winHeight: window.innerHeight
        });
    };
    useEffect(() => {
        window.addEventListener("resize", detectSize);

        return () => {
            window.removeEventListener("resize", detectSize);
        };
    }, [windowDimension]);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <Stack horizontal>
                    <div className={styles.headerContainer}>
                        <nav>
                            <ul className={styles.headerNavList}>
                                <li className={styles.headerNavLeftMargin}>
                                    <a href="https://www.lifeatford.com/" target={"_blank"} title="Life@Ford link">
                                        {windowDimension.winWidth <= 350 && windowDimension.winWidth > 320 && (
                                            <img
                                                src={logo}
                                                alt="Life@Ford Logo"
                                                aria-label="Link to Life@Ford"
                                                width="145px"
                                                height="33.5px"
                                                style={{ marginTop: "5px" }}
                                            />
                                        )}
                                        {windowDimension.winWidth <= 320 && (
                                            <img
                                                src={logo}
                                                alt="Life@Ford Logo"
                                                aria-label="Link to Life@Ford"
                                                width="100px"
                                                height="23px"
                                                style={{ marginTop: "10.5px" }}
                                            />
                                        )}
                                        {windowDimension.winWidth > 350 && (
                                            <img src={logo} alt="Life@Ford Logo" aria-label="Link to Life@Ford" width="195px" height="45px" />
                                        )}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <a href="https://www.lifeatford.com/en-US/searchresults/" target={"_blank"} title="Life@Ford Search">
                        <ActionButton className={styles.searchButton}>
                            <Search20Filled className={styles.icon} />
                            {windowDimension.winWidth <= 435 && <div></div>}
                            {windowDimension.winWidth > 435 && (
                                <div style={{ fontWeight: "600", fontFamily: "FordAntennaRegular", paddingTop: "2.5px" }}>SEARCH</div>
                            )}
                        </ActionButton>
                    </a>
                </Stack>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
