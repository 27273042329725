import React, { useState } from "react";
import { ActionButton, Icon, IconButton, Stack } from "@fluentui/react";
import "./DislikeForm.css";

import "../../assets/fonts/FordMotion/woff/FordMotion-Regular.woff";

import {
    Accordion,
    AccordionHeader,
    AccordionItem,
    AccordionPanel,
    FluentProvider,
    Button,
    Label,
    Textarea,
    AccordionToggleEventHandler
} from "@fluentui/react-components";
import { EmojiSad24Regular, CheckmarkCircle24Regular } from "@fluentui/react-icons";

interface LikeBtnProps {
    messageId: number;
    onSendFeedback: (messageId: number, action: string) => void;
    onSendFeedbackMessage: (messageId: number, feedback: string, feedbackCategory: string | null) => void;
    setAnswerFeedbackForm: (status: boolean) => void;
    feedbackForm: boolean;
}
export const DislikeForm = ({ messageId, onSendFeedback, onSendFeedbackMessage, setAnswerFeedbackForm }: LikeBtnProps) => {
    const [feedbackForm, setFeedbackForm] = useState(true);
    const [message, setMessage] = useState("");

    const maxLength = 1000;

    const onFeedbackClicked = async () => {
        onSendFeedback(messageId, "up");
    };
    const toggleFeedbackForm = () => {
        setFeedbackForm(!feedbackForm);
    };
    const onSubmitFeedBackForm = async (selected: string | null) => {
        setFeedbackForm(!feedbackForm);
        onSendFeedbackMessage(messageId, message, selected);
    };

    const [selected, setSelected] = useState<string | null>(null);
    const handleClick = (value: string) => {
        setSelected(value);
    };

    const [feedbackExpanded, setFeedbackExpanded] = useState<boolean>(false);
    const handleToggle = React.useCallback<AccordionToggleEventHandler>((_, data) => {
        if (feedbackExpanded === false) {
            setFeedbackExpanded(prevExpanded => {
                return !prevExpanded;
            });
        }
    }, []);

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    if (feedbackForm) {
        return (
            <>
                <Accordion collapsible onToggle={handleToggle} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                    <AccordionItem value="0">
                        <AccordionHeader
                            id="accordion-header"
                            expandIconPosition="end"
                            className="accordian-header"
                            icon={<EmojiSad24Regular className="icon" />}
                            size="extra-large"
                        >
                            <div style={{ width: "100%", textAlign: "left" }}>
                                <span style={{ float: "left", fontWeight: "600", color: "black", marginBottom: "2.8px" }}>Why wasn't this helpful?</span>{" "}
                                {!feedbackExpanded && (
                                    <span
                                        style={{
                                            float: "right",
                                            fontSize: "16px",
                                            fontWeight: "600",
                                            color: isHovered ? "#2047ef" : "black",
                                            marginRight: "5px"
                                        }}
                                    >
                                        Give Feedback!
                                    </span>
                                )}
                            </div>
                        </AccordionHeader>
                        <AccordionPanel>
                            <div className="panel">
                                <div className="radio-buttons">
                                    <Button
                                        id="button"
                                        className={`button ${selected === "Inaccurate" ? "button-checked" : ""}`}
                                        onClick={() => (selected === "Inaccurate" ? handleClick("") : handleClick("Inaccurate"))}
                                        style={{
                                            backgroundColor: selected === "Inaccurate" ? "#226dec" : "#eff2f7",
                                            color: selected === "Inaccurate" ? "white" : "#3a85d5"
                                        }}
                                    >
                                        <b>Inaccurate</b>
                                    </Button>
                                    <Button
                                        id="button"
                                        className={`button ${selected === "Inappropriate" ? "button-checked" : ""}`}
                                        onClick={() => (selected === "Inappropriate" ? handleClick("") : handleClick("Inappropriate"))}
                                        style={{
                                            backgroundColor: selected === "Inappropriate" ? "#226dec" : "#eff2f7",
                                            color: selected === "Inappropriate" ? "white" : "#3a85d5"
                                        }}
                                    >
                                        <b>Inappropriate</b>
                                    </Button>
                                    <Button
                                        id="button"
                                        className={`button ${selected === "Offensive" ? "button-checked" : ""}`}
                                        onClick={() => (selected === "Offensive" ? handleClick("") : handleClick("Offensive"))}
                                        style={{
                                            backgroundColor: selected === "Offensive" ? "#226dec" : "#eff2f7",
                                            color: selected === "Offensive" ? "white" : "#3a85d5"
                                        }}
                                    >
                                        <b>Offensive</b>
                                    </Button>
                                    <Button
                                        id="button"
                                        className={`button ${selected === "Irrelevant" ? "button-checked" : ""}`}
                                        onClick={() => (selected === "Irrelevant" ? handleClick("") : handleClick("Irrelevant"))}
                                        style={{
                                            backgroundColor: selected === "Irrelevant" ? "#226dec" : "#eff2f7",
                                            color: selected === "Irrelevant" ? "white" : "#3a85d5"
                                        }}
                                    >
                                        <b>Irrelevant</b>
                                    </Button>
                                    <Button
                                        id="button"
                                        className={`button ${selected === "Other" ? "button-checked" : ""}`}
                                        onClick={() => (selected === "Other" ? handleClick("") : handleClick("Other"))}
                                        style={{
                                            backgroundColor: selected === "Other" ? "#226dec" : "#eff2f7",
                                            color: selected === "Other" ? "white" : "#3a85d5"
                                        }}
                                    >
                                        <b>Other</b>
                                    </Button>
                                </div>
                                <div className="text-field">
                                    <Stack
                                        style={{
                                            width: "100%",
                                            height: "200px"
                                        }}
                                    >
                                        <textarea
                                            style={{
                                                width: "100%",
                                                height: "200px",
                                                padding: "12px 20px",
                                                boxSizing: "border-box",
                                                border: "1px solid #ccc",
                                                borderRadius: "4px",
                                                backgroundColor: "white",
                                                fontSize: "16px",
                                                fontWeight: "500",
                                                resize: "none",
                                                borderColor: "#2047ef",
                                                fontFamily: "FordMotionRegular"
                                            }}
                                            value={message}
                                            className="feedbackMessage"
                                            title="Type your feedback here"
                                            placeholder="Add more details here. To help protect your privacy, please avoid including any personal information such as your name or email address."
                                            onChange={(e: { target: { value: React.SetStateAction<string> } }) => {
                                                if (!e.target.value) {
                                                    setMessage("");
                                                } else if (e.target.value.length <= maxLength) {
                                                    setMessage(e.target.value);
                                                }
                                            }}
                                        />
                                        <Stack
                                            horizontal
                                            style={{
                                                justifyContent: "right",
                                                marginRight: "10px",
                                                color: message.length === 0 ? "gray" : "unset",
                                                marginTop: "10px",
                                                fontSize: "12px",
                                                fontFamily: "FordMotionRegular" 
                                            }}
                                        >
                                            {message.length}/{maxLength}
                                        </Stack>
                                    </Stack>
                                </div>
                                <div>
                                    <ActionButton
                                        id="submit-button"
                                        onClick={() => onSubmitFeedBackForm(selected)}
                                        title="Click 'Submit' button to record feedback"
                                        disabled={!message.trim() && !selected}
                                    >
                                        <b>Submit</b>
                                    </ActionButton>
                                </div>
                            </div>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </>
        );
    } else if (!feedbackForm) {
        return (
            <>
                <Accordion collapsible>
                    <AccordionItem value="0">
                        <AccordionHeader
                            id="accordion-header"
                            expandIconPosition="start"
                            expandIcon={<CheckmarkCircle24Regular color="green" id="check-icon" />}
                            size="extra-large"
                        >
                            <span style={{ float: "left", fontWeight: "600", color: "black" }}>Thanks for your feedback!</span>
                        </AccordionHeader>
                    </AccordionItem>
                </Accordion>
            </>
        );
    } else {
        return <></>;
    }
};