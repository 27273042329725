import { AskRequest, AskResponse, ChatRequest, Conversation } from "./models";

const clientId = "ae5fc2ab-d8a9-4544-b282-23bf5d57bfed";
//const BASE_URL = "http://localhost:5000";
const BASE_URL = "";

function getAccessToken() {
    // todo
    try {
        const keyLookup = "msal." + clientId + ".active-account-filters";
        const homeAccountId = JSON.parse(localStorage[keyLookup]).homeAccountId;
        const accessTokenKey = `${homeAccountId}-login.windows.net-idtoken-` + clientId + `-c990bb7a-51f4-439b-bd36-9c07fb1041c0---`;
        const accessToken = JSON.parse(localStorage[accessTokenKey]).secret;
        console.log("accessToken received");
        return accessToken;
    } catch (e) {
        console.error(e);
        console.log("user not logged in");
    }
}

export async function askApi(options: AskRequest): Promise<AskResponse> {
    const response = await fetch(BASE_URL + "/ask", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

function setConversationId(conversationId: string) {
    sessionStorage.setItem("conversationId", conversationId);
    sessionStorage.setItem("conversationLastModifiedDt", Date.now().toString());
}

export function resetConversationId() {
    sessionStorage.removeItem("conversationId");
    sessionStorage.removeItem("conversationLastModifiedDt");
}

function getConversationId() {
    const id = sessionStorage.getItem("conversationId");
    const t = sessionStorage.getItem("conversationLastModifiedDt");
    if (id == null || t == null) {
        resetConversationId();
        return null;
    }
    const ALLOWED_WAIT = 24 * 60 * 60 * 1000;
    // const ALLOWED_WAIT = 10 * 1000;
    const now = Date.now();
    const lastModified = parseInt(t);
    if (now - lastModified < ALLOWED_WAIT) {
        return id;
    }
    resetConversationId();
    return null;
}

export async function chatApi(options: ChatRequest): Promise<Conversation> {
    const conversationId = getConversationId();
    const webroles_token = await getWebroleToken();
    const response = await fetch(BASE_URL + "/chat", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        },
        body: JSON.stringify({
            conversationId,
            history: options.history,
            webroles_token: webroles_token,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });

    const parsedResponse: Conversation = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error((parsedResponse as any).error || "Unknown error");
    }
    setConversationId(parsedResponse.id);
    return parsedResponse;
}

export const fetchWebroleToken = async () => {
    const res = await fetch(`${BASE_URL}/webroles`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    });
    const obj: { token: string; exp: number } = await res.json();
    return obj;
};

export const getWebroleToken = async () => {
    try {
        const token_exp = window.localStorage.getItem("WEBROLES_EXP");
        if (!token_exp) {
            throw new Error("no exp");
        }
        const exp = parseInt(token_exp);
        if (exp * 1000 < Date.now() + 1 * 60 * 60 * 1000) {
            throw Error("Expiring soon");
        }
        const token = window.localStorage.getItem("WEBROLES_TOKEN");
        if (!token) throw new Error("No token");
        return token;
    } catch (e) {
        window.localStorage.removeItem("WEBROLES_TOKEN");
        window.localStorage.removeItem("WEBROLES_EXP");
        try {
            const obj = await fetchWebroleToken();
            window.localStorage.setItem("WEBROLES_TOKEN", obj.token);
            window.localStorage.setItem("WEBROLES_EXP", obj.exp.toFixed());
            return obj.token;
        } catch (e) {
            console.error("Failed to fetch webroles from API /webroles", e);
        }
    }
};

export const sendFeedback = async (messageId: number, action: string) => {
    const conversationId = getConversationId();

    const res = await fetch(`${BASE_URL}/feedback/${conversationId}/${messageId}/${action}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        }
    });
    const data: Conversation = await res.json();
    setConversationId(data.id);
    return data;
};

export const sendFeedbackMessage = async (messageId: number, feedback: string | null, feedbackCategory: string | null) => {
    const conversationId = getConversationId();
    if (!feedbackCategory) {
        feedbackCategory = "na";
    }
    if (!feedback) {
        feedback = "na";
    }
    if (feedback.includes("/") || feedback.includes("\\")) {
        feedback = feedback.replace(/[\/\\]/g, "%");
    }
    const encoded_feedback = encodeURIComponent(feedback);
    const encoded_feedbackCategory = encodeURIComponent(feedbackCategory);
    console.log("FrontEnd: " + encoded_feedback + encoded_feedbackCategory);
    const res = await fetch(`${BASE_URL}/feedbackmessage/${conversationId}/${messageId}/${encoded_feedback}/${encoded_feedbackCategory}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getAccessToken()}`
        }
    });
    const data: Conversation = await res.json();
    setConversationId(data.id);
    return data;
};

export const getHistory = async () => {
    const conversationId = getConversationId();
    const res = await fetch(`${BASE_URL}/history/${conversationId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${getAccessToken()}`
        }
    });
    const data: Conversation = await res.json();
    if (!data.id || !data.messages) {
        throw Error((data as any).error || "Something went wrong");
    }
    setConversationId(data.id);
    return data;
};

export function getCitationFilePath(citation: string): string {
    return `${BASE_URL}/content/${citation}`;
}
